import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-plugin-image";

export type IBanner = {
  slug: string;
  bannerProperties: {
    background: {
      mediaItemUrl: string;
      localFile: {
        childImageSharp: {
          gatsbyImageData: GatsbyImageProps;
        };
      };
    };
    abilitato: boolean;
    button: {
      title: string;
      href: string;
    };
  };
};

export const useBanner = (bannerId: IBanner["slug"]) => {
  const {
    allWpBanner: { nodes: banners },
  } = useStaticQuery(graphql`
    query {
      allWpBanner {
        nodes {
          slug
          bannerProperties {
            background {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            abilitato
            button {
              title
              href
            }
          }
        }
      }
    }
  `);
  const { bannerProperties: banner } = banners.find(
    (banner: IBanner) => banner.slug === bannerId
  );
  return { banner };
};
