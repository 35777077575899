import React from 'react';
import { IoMdClose } from 'react-icons/io';
import Modal from 'react-bootstrap/esm/Modal';
import { StaticImage } from 'gatsby-plugin-image';

interface PopupProps {
  popupOpen: boolean;
  setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Popup = ({ popupOpen, setPopupOpen }: PopupProps) => {
  const onClose = () => {
    setPopupOpen(false);
  };
  const popupImage = './images/ferie-agosto-2023.png';

  return (
    <Modal
      show={popupOpen}
      popupOpen
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body style={{ padding: 0 }}>
        <div className="position-relative">
          <div
            style={{ zIndex: 100, marginLeft: '10px', marginTop: '10px' }}
            className="position-absolute start-0 top-0"
          >
            <IoMdClose
              color="#445a5e"
              size={36}
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div>
            <StaticImage
              style={{ borderRadius: '7px' }}
              quality={100}
              src={popupImage}
              alt="Ferie Agosto"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
