import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../ui/Button";
import { useBanner } from "../../hooks/useBanner";

export const Banner = ({ bannerName }) => {
  const { banner } = useBanner(bannerName);
  const { background, button } = banner;
  return (
    <div className="position-relative">
      <GatsbyImage
        className="w-100"
        image={background.localFile.childImageSharp.gatsbyImageData}
        alt="prenota"
      />
      <div className="position-absolute top-50 start-50 translate-middle">
        {button.href && (
          <Link to={button.href}>
            <Button className="text-primary bg-primary text-white text-uppercase">
              {button.title}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
