import React, { useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Hero } from "../components/hero/Hero";
import { Banner } from "../components/hero/Banner";
import { Layout } from "../components/layout/Layout";
import { WIP } from "../components/layout/WIP";
import { Popup } from "../components/popup";
import { IBanner } from "../hooks/useBanner";
// import { MenuDiNatale } from "../components/hero/MenuDiNatale";

const Home = ({ data }) => {
  const { wpSezione, allWpBanner } = data;
  const popupTimeout = new Date() < new Date(2023, 7, 30);
  const [popupOpen, setPopupOpen] = useState(popupTimeout);
  const isWIP = false;
  return isWIP ? (
    <WIP />
  ) : (
    <>
      <Helmet title="Ristorante A Mangiare" defer={false} />
      <Popup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
      <Layout headerPosition="position-absolute">
        <Hero data={wpSezione} />
        {allWpBanner.nodes.map((banner: IBanner) =>
          banner.bannerProperties.abilitato ? (
            <Banner key={banner.slug} bannerName={banner.slug} />
          ) : null
        )}
        {/* <MenuDiNatale /> */}
      </Layout>
    </>
  );
};

export default Home;

export const data = graphql`
  query {
    wpSezione(slug: { eq: "home" }) {
      title
      content
      homeSlideCustomFields {
        slides {
          title
          text
          color
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, quality: 100)
              }
            }
          }
        }
      }
    }
    allWpBanner(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        bannerProperties {
          abilitato
        }
      }
    }
  }
`;
