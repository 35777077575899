import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Hero = ({ data }) => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const { slides } = data.homeSlideCustomFields;
  const slidesNumber = slides.length;

  const YL = useRef<HTMLDivElement>(null);
  const YR = useRef<HTMLDivElement>(null);
  const RL = useRef<HTMLDivElement>(null);
  const RR = useRef<HTMLDivElement>(null);
  const VL = useRef<HTMLDivElement>(null);
  const VR = useRef<HTMLDivElement>(null);
  const BL = useRef<HTMLDivElement>(null);
  const BR = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = 4200;
    const interval = setInterval(() => {
      setSelectedSlide(prevSlide => (prevSlide + 1) % slidesNumber);
    }, timeout);
    return () => clearInterval(interval);
  }, [slidesNumber]);

  const slideStyles = useMemo(
    () => [
      {
        zIndices: [3, 3, 2, 2, 1, 1, 4, 4],
        transforms: [
          'translateX(0)',
          'translateX(0)',
          '',
          '',
          'translateX(0)',
          'translateX(0)',
          'translateY(-100%)',
          'translateY(100%)',
        ],
      },
      {
        zIndices: [4, 4, 3, 3, 2, 2, 1, 1],
        transforms: [
          'translateX(-100%)',
          'translateX(100%)',
          '',
          '',
          'translateY(0%)',
          'translateY(0%)',
          '',
          '',
        ],
      },
      {
        zIndices: [1, 1, 4, 4, 3, 3, 2, 2],
        transforms: [
          'translateX(0%)',
          'translateX(0%)',
          'translateY(-100%)',
          'translateY(100%)',
          '',
          '',
          '',
          '',
        ],
      },
      {
        zIndices: [2, 2, 1, 1, 4, 4, 3, 3],
        transforms: [
          '',
          '',
          'translateY(0%)',
          'translateY(0%)',
          'translateX(-100%)',
          'translateX(100%)',
          '',
          '',
        ],
      },
    ],
    []
  );

  useEffect(() => {
    const { zIndices, transforms } = slideStyles[selectedSlide];
    const [ylz, yrz, rlz, rrz, vlz, vrz, blz, brz] = zIndices;
    const [ylt, yrt, rlt, rrt, vlt, vrt, blt, brt] = transforms;
    YL.current?.style.setProperty('z-index', ylz.toString());
    YR.current?.style.setProperty('z-index', yrz.toString());
    RL.current?.style.setProperty('z-index', rlz.toString());
    RR.current?.style.setProperty('z-index', rrz.toString());
    VL.current?.style.setProperty('z-index', vlz.toString());
    VR.current?.style.setProperty('z-index', vrz.toString());
    BL.current?.style.setProperty('z-index', blz.toString());
    BR.current?.style.setProperty('z-index', brz.toString());
    YL.current?.style.setProperty('transform', ylt);
    YR.current?.style.setProperty('transform', yrt);
    RL.current?.style.setProperty('transform', rlt);
    RR.current?.style.setProperty('transform', rrt);
    VL.current?.style.setProperty('transform', vlt);
    VR.current?.style.setProperty('transform', vrt);
    BL.current?.style.setProperty('transform', blt);
    BR.current?.style.setProperty('transform', brt);
  }, [selectedSlide, slideStyles]);

  return (
    <Fragment>
      <div style={{ height: '85vh' }}>
        <div className="position-relative h-100 w-100 overflow-hidden">
          {/* FIRST */}
          <div className="row">
            <div
              ref={YL}
              className="w-50 h-100 position-absolute top-0 start-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[0].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 ms-auto"
              >
                <GatsbyImage
                  alt="piatto"
                  image={
                    slides[0].image.localFile?.childImageSharp.gatsbyImageData
                  }
                />
              </div>
            </div>
            <div
              ref={YR}
              className="w-50 h-100 position-absolute top-0 end-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[0].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 me-auto"
              >
                <div
                  className="text-primary text-uppercase"
                  dangerouslySetInnerHTML={{ __html: slides[0].title }}
                />
                <div
                  className="text-secondary"
                  dangerouslySetInnerHTML={{ __html: slides[0].text }}
                />
              </div>
            </div>
          </div>
          {/* SECOND */}
          <div className="row">
            <div
              ref={RL}
              className="w-50 h-100 position-absolute top-0 start-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[1].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 ms-auto"
              >
                <GatsbyImage
                  alt="piatto"
                  image={
                    slides[1].image.localFile?.childImageSharp.gatsbyImageData
                  }
                />
              </div>
            </div>
            <div
              ref={RR}
              className="w-50 h-100 position-absolute top-0 end-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[1].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 me-auto"
              >
                <div
                  className="text-primary text-uppercase"
                  dangerouslySetInnerHTML={{ __html: slides[1].title }}
                />
                <div
                  className="text-secondary"
                  dangerouslySetInnerHTML={{ __html: slides[1].text }}
                />
              </div>
            </div>
          </div>
          {/* THIRD */}
          <div className="row">
            <div
              ref={VL}
              className="w-50 h-100 position-absolute top-0 start-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[2].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 ms-auto"
              >
                <GatsbyImage
                  alt="piatto"
                  image={
                    slides[2].image.localFile?.childImageSharp.gatsbyImageData
                  }
                />
              </div>
            </div>
            <div
              ref={VR}
              className="w-50 h-100 position-absolute top-0 end-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[2].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 me-auto"
              >
                <div
                  className="text-primary text-uppercase"
                  dangerouslySetInnerHTML={{ __html: slides[2].title }}
                />
                <div
                  className="text-secondary"
                  dangerouslySetInnerHTML={{ __html: slides[2].text }}
                />
              </div>
            </div>
          </div>
          {/* FOURTH */}
          <div className="row">
            <div
              ref={BL}
              className="w-50 h-100 position-absolute top-0 start-0"
              style={{
                transition: 'transform 1s ease-in-out',
                backgroundColor: slides[3].color,
              }}
            >
              <div
                style={{ maxWidth: '512px' }}
                className="position-relative piatto-slide w-100 ms-auto"
              >
                <GatsbyImage
                  alt="piatto"
                  image={
                    slides[3].image.localFile?.childImageSharp.gatsbyImageData
                  }
                />
              </div>
            </div>
          </div>
          <div
            ref={BR}
            className="w-50 h-100 position-absolute top-0 end-0"
            style={{
              transition: 'transform 1s ease-in-out',
              backgroundColor: slides[3].color,
            }}
          >
            <div
              style={{ maxWidth: '512px' }}
              className="position-relative piatto-slide w-100 me-auto"
            >
              <div
                className="text-primary text-uppercase"
                dangerouslySetInnerHTML={{ __html: slides[3].title }}
              />
              <div
                className="text-secondary"
                dangerouslySetInnerHTML={{ __html: slides[3].text }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
